import Handlebars from 'handlebars';

Handlebars.registerHelper('notEmpty', function (value) {
  return !Handlebars.Utils.isEmpty(value);
});

Handlebars.registerHelper('commas', function (arr, propName) {
  if (Array.isArray(arr)) {
    return typeof propName === 'string' ? arr.map(item => item[propName]).join(', ') : arr.join(', ');
  }
  return arr;
});

Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
  return arg1 && arg1 == arg2 ? options.fn(this) : options.inverse(this);
});

export const compileTemplates = (templates) => Object.values(templates).forEach(compileTemplate);

export const compileTemplate = (config) => {
  config.template = Handlebars.compile(config.source)
  return config;
};

export const renderTpl = (config, data) => {
  config.target.innerHTML = config.template(data);
  if ($ && $.fn && $.fn.tooltip) {
    $(config.target).find('[data-toggle="tooltip"]').tooltip();
  }
};

const locale = (typeof pageData !== 'undefined' && pageData.locale) || document.documentElement.getAttribute('lang') || document.body.getAttribute('lang') || 'ja';
const currency = (typeof pageData !== 'undefined' && pageData.currency) || 'JPY';
const precision = locale === 'ja' ? 0 : 2;
const nf = new Intl.NumberFormat(locale);

export const formatCurrency = number => nf.format(number.toFixed(precision)) + ' ' + currency;

export const formatDate = date => {
  if (typeof date === 'object' && date.constructor.name === 'Date') {
    return date.toLocaleString(locale);
  }
  return date;
};

export const formatStatus = status => typeof status === 'string' ? status.split('_').join(' ') : '';

export const uniqueListener = (elem, event, callback) => {
  elem.removeEventListener(event, callback);
  elem.addEventListener(event, callback);
};

export const locationOrigin = () => {
  return window.location.protocol + "//"
    + window.location.hostname
    + (window.location.port ? ':' + window.location.port : '');
};

export const DTFormat = 'YYYY.MM.DD HH:mm';
export const DTFormatLong = 'YYYY年MM月DD日 HH時mm分';
export const DTFormatForPicker = 'Y.m.d H:i';
const timezone = (typeof pageData !== 'undefined' && pageData.user?.timezone) || 'Japan';

export const pickerToMoment = input => {
  if (moment.isDate(input)) {
    const pickerTime = moment(input).format(DTFormat);
    return moment.tz(pickerTime, DTFormat, timezone);
  } else {
    return input;
  }
};
export const momentToAPI = input => {
  if (moment.isMoment(input)) {
    return input.toISOString();
  } else {
    return input;
  }
};
export const momentToRemote = input => {
  if (moment.isMoment(input)) {
    return moment(input).format(DTFormat + ' z');
  } else {
    return input;
  }
};
export const momentToRemoteLong = input => {
  if (moment.isMoment(input)) {
    return moment(input).format(DTFormatLong + ' (z)');
  } else {
    return input;
  }
};
export const momentToLocal = input => {
  if (moment.isMoment(input)) {
    return moment(input).local().format(DTFormat) + ` (${moment.tz.guess()})`;
  } else {
    return input;
  }
};

export const pickerToSelectedTimezone = (input, selectedTimezone) => {
  const momentTime = pickerToMoment(input);
  const dateTimeInZone = momentTime.clone().tz(selectedTimezone);
  return dateTimeInZone.format(DTFormat) + ` <b>(${selectedTimezone})</b>`;
};

export const pickerToLocal = input => momentToLocal(pickerToMoment(input));
export const pickerToRemote = input => momentToRemote(pickerToMoment(input));
export const pickerToRemoteLong = input => momentToRemoteLong(pickerToMoment(input));
export const pickerToAPI = input => momentToAPI(pickerToMoment(input));

export const localePrefix = () => `/${document.body.lang || 'ja'}`;