import {formatStatus, DTFormat, formatCurrency} from './helpers.js';

export class tables {
  static init(){
    const locale = (typeof pageData !== 'undefined' && pageData.locale) || document.documentElement.getAttribute('lang') || document.body.getAttribute('lang') || 'ja';

    if(typeof pageData !== 'undefined' && pageData.latestReleases){
      $('.table-latest').bootstrapTable({
        columns: latestReleasesColumns(),
        data: pageData.latestReleases,
        sortName: 'created_at',
        sortOrder: 'desc', 
        locale: locale,
      })
    }

    if(typeof pageData !== 'undefined' && pageData.allReleases){
      $('.table-all').bootstrapTable({
        columns: latestReleasesColumns(),
        data: pageData.allReleases,
        pagination: true,
        sortName: 'created_at',
        sortOrder: 'desc',
        locale: locale,
      })
    }

    if(typeof pageData !== 'undefined' && pageData.mediaLists){
      $('.table-media-lists').bootstrapTable({
        columns: mediaListsColumns(),
        data: pageData.mediaLists,
        pagination: false,
        locale: locale,
      })
    }
  };
};

const titleFormatter = titleValue => `<p class='table-item-title'> ${titleValue} </p>`;

export const statusFormatter = (status, row) => {
  if(!status) {
    return '';
  }
  const statusFormatted = formatStatus(row?.status_name || status);
  const chipClass =
    (status == 'draft' || status == 'waiting_for_payment') ? 'draft' :
    (status == 'submitted') ? 'submitted' :
    (status == 'content_ready') ? 'contentReady' :
    (status == 'pending_customer_confirmation') ? 'pendingConfirmation' :
    (status == 'ready_to_publish') ? 'readyToPublish' :
    (status == 'published') ? 'published' :
    (status == 'report_ready') ? 'reportReady' :
    (status == 'completed') ? 'completed' :
    (status == 'paid') ? 'success' :
    (status == 'submitted') ? 'danger' :
    'default';
    return `<div class='chip size-medium variant-${chipClass}'><p>${statusFormatted}</p></div>`;
};

const dateFormatter = value => !value ? '-' : moment(value).tz(pageData.user.timezone).format(DTFormat);

const currencyFormatter = value => formatCurrency(parseFloat(value));

const latestReleasesColumns = () => [
  {
    field: 'title',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.title : '',
    width: 300,
    sortable: true,
    formatter: titleFormatter,
  },
  {
    field: 'created_at',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.created_at : '',
    sortable: true, 
    formatter: dateFormatter,
  },
  {
    field: 'publish_at',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.publish_at : '',
    sortable: true, 
    formatter: dateFormatter,
  },
  {
    field: 'status',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.status : '',
    sortable: true,
    formatter: statusFormatter,
  },
  {
    field: 'total_dollar_price',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.total_dollar_price : '',
    sortable: true, 
    formatter: currencyFormatter,
  },
  {
    field: 'actions',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.actions : '',
    sortable: false,
  },
];

const mediaListsColumns = () => [
  {
    field: 'region_name',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.region_name : '',
    width: 150,
    sortable: true,
  },
  {
    field: 'name',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.name : '',
    width: 150,
    sortable: true,
  },
  {
    field: 'description',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.description : '',
  },
  {
    field: 'attachment_url',
    title: typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.attachment_url : '',
    width: 150,
    align: 'center',
    formatter: url => `<a href="${url}" target="_blank" class="btn btn-outline-primary btn-sm btn-block">
      <span class="mdi mdi-download"></span>
      <span class="sr-only">${typeof pageData !== 'undefined' && pageData.columnTitles ? pageData.columnTitles.attachment_url : 'Download'}</span>
    </a>`,
  },
];
