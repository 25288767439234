import { compileTemplate, renderTpl } from "./helpers";

export class Notifier {
  wrapperSel = '.notifier';
  templateSel = '.tpl-notifier';

  constructor(){
    this.dict = notifierDict;

    if(typeof this.dict === 'undefined'){
      return false;
    }

    this.initElements();
    this.initTemplates();
  };

  initElements(){
    this.dom = {
      wrapper: document.querySelector(this.wrapperSel),
      template: document.querySelector(this.templateSel),
    };

    $(this.dom.wrapper).on('click', '.close', () => this.hide());
  };

  initTemplates(){
    this.template = {source: this.dom.template.innerHTML, target: this.dom.wrapper};
    compileTemplate(this.template);
  };

  show(subject, options = {}){
    if(typeof this.dict[subject] === 'undefined'){
      return false;
    }

    const opts = Object.assign({}, this.dict[subject], options);

    clearTimeout(this.timer);

    renderTpl(this.template, {
      message: opts.message,
      variant: opts.variant,
      progress: opts.progress,
      dismissible: opts.dismissible,
    });

    if(opts.autoHide){
      this.timer = setTimeout(() => {this.hide()}, typeof opts.autoHide === 'number' ? opts.autoHide : 1000)
    }
  };

  hide(){
    this.dom.wrapper.innerHTML = '';
  };
};