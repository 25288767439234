import { prCreator } from './pr-creator.js';
import { tables, statusFormatter } from './tables.js';
import { API } from './api.js';
import { uniqueListener, DTFormat, DTFormatForPicker, pickerToLocal, localePrefix, pickerToSelectedTimezone } from './helpers.js';
import Handlebars from 'handlebars';

const init = () => {
  // initHeaderSize();
  initNavigation();
  initHandlebarsPartials();
  initTooltips();
  initStepsNumbers();
  initScrollbar();
  initCollapsible();
  initDatePickers();
  initStatusFormatting();
  initMultiModal();
  initAuthForms();
  initSaveAndShowModal();
  initActivityFeed();
  initVideoPauseOnModalClose();
  handleRegistrationButton();
  tables.init();
  const prCreatorInstance = prCreator.init();
  initInquiryModal(prCreatorInstance);
};

// const initHeaderSize = () => {
//   function changeHeaderSize() {
//     let sidebarWidth = $('.sidebar').outerWidth();
//     let header = $('.page-header');
//     let headerWidth = header.outerWidth();
//     header.css({'width': headerWidth - sidebarWidth + 'px', 'margin-left': sidebarWidth});
//   }

//   $(window).off('resize.changeHeaderSize').on('resize.changeHeaderSize', changeHeaderSize).trigger('resize.changeHeaderSize');
// };

const initNavigation = () => {
  const toggler = document.querySelector('.menu-toggler');
  const sidebar = document.querySelector('.main-sidebar');
  const body = document.querySelector('body');

  if (toggler && sidebar && body) {
    const openClass = 'menu-open';

    toggler.addEventListener('click', () => {
      if (!(sidebar.classList.contains('main-sidebar-small'))) {
        toggler.classList.toggle(openClass);
      }
      sidebar.classList.toggle(openClass);
      body.classList.toggle(openClass);
    });
  }


};


const initHandlebarsPartials = () => {
  Array.from(document.querySelectorAll('script.tpl-partial[data-name]:not(.registered)')).forEach(partial => {
    partial.classList.add('registered');
    Handlebars.registerPartial(partial.dataset.name, partial.innerHTML);
  });
};


const initTooltips = () => {
  $('[data-toggle="tooltip"]').tooltip();
};

const initStepsNumbers = () => {
  const stepNumbers = document.querySelectorAll('.tab-pane .section-title');
  let i = 1;

  for (let stepNumber of stepNumbers) {
    const stepCounter = document.createElement('div');
    stepCounter.classList.add('col-12', 'no-gutter', 'd-block', 'd-lg-none', 'text-medium', 'mb-3', 'step-counter');
    stepCounter.innerHTML = `Step ${i++}/${stepNumbers.length}`;
    stepNumber.prepend(stepCounter);
  }

};

const initScrollbar = () => {
  $('.scrollable').mCustomScrollbar({
    theme: 'dark-3',
    scrollButtons: {
      enable: true
    }
  });
};

const initCollapsible = () => {
  $('.btn-card').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('rotated');
    $(this).closest('.card').toggleClass('collapsed');
  });
};

export const updateDatePickerInfo = (input) => {
  const inputMoment = moment(input.value, DTFormat);
  const datetime = $(input).datetimepicker('getValue');
  const infoMsg = input.parentElement.parentElement.querySelector('.info-msg');

  if (infoMsg) {
    infoMsg.classList.toggle('d-none', !inputMoment.isValid());
    infoMsg.querySelector('span').innerHTML = pickerToLocal(datetime);

    const existingPs = infoMsg.querySelectorAll('p.regional-time');
    existingPs.forEach(p => p.remove());

    if (pageData && Array.isArray(pageData.order.selectedRegionsWithPublishingTime)) {
      const uniqueRegions = {}; // to store unique regions
      pageData.order.selectedRegionsWithPublishingTime.forEach(region => {
        if (region.region_time_zone && !uniqueRegions[region.region_name]) {
          const regionalTime = pickerToSelectedTimezone(datetime, region.region_time_zone);

          uniqueRegions[region.region_name] = regionalTime;  // store the region in an object to avoid duplication

          // create an element only if the region has not yet been added
          const regionP = document.createElement('p');
          regionP.classList.add('regional-time', 'mb-0');
          regionP.innerHTML = `<b>${region.region_name}</b>: ${regionalTime}`;
          infoMsg.appendChild(regionP);
        }
      });
    }
  }
};

const initDatePickers = () => {
  const input = document.querySelector('#datetime');

  if (input && pageData && pageData.order) {
    // const currentDateTime = new Date(moment(pageData.order.currentJapanTime).tz(pageData.user.timezone).format(DTFormat));
    const minDateTime = moment(moment.tz(pageData.order.minPublishAt, pageData.user.timezone).format('YYYY-MM-DDTHH:mm'));
    minDateTime.add(1.5, 'hour').startOf('hour');
    /*
    Adding 1.5 hours and rounding down gives the user from 0:30 to 1:30 to finish filling out the form, while keeping round hours.

    Details:
      - the 0.5h is added because if backend has minPublishAt set to 3:59 (exactly 48h from now), and we were to use this value,
        then by the time user would finish filling the form, the 3:59 time would be in less then 48h from now,
        and thus would be invalid – so we add half an hour to give the user time to finish filling out the form
      - on top of that, we want to have nice hours, no pesky minutes or anything, so we add an hour and then round down
        (in other words, we set it to the start of the next hour) that way (ignoring the 0.5h explained above)
        3:59 + 1h = 4:59, but rounded down becomes 4:00, and 4:00 + 1h = 5:00, rounded down is still 5:00
      - as a result, this operation gives us (minPublishAt => plus 1.5h => rounded down):
          - 3:00 => 4:30 => 4:00 (1:00 after minPublishAt)
          - 3:29 => 4:59 => 4:00 (0:31 after minPublishAt)
          - 3:30 => 5:00 => 5:00 (1:30 after minPublishAt)
          - 3:59 => 5:29 => 5:00 (1:01 after minPublishAt)
    */

    $.datetimepicker.setLocale(document.body.lang || 'ja');

    $(input).datetimepicker({
      format: DTFormatForPicker,
      minDateTime: minDateTime.toDate(),
      defaultDate: minDateTime.toDate(),
      defaultTime: minDateTime.toDate(),
      onChangeDateTime: datetime => {
        input.dispatchEvent(new Event('change'));
        updateDatePickerInfo(input);
      },
      onGenerate: (datetime, $input) => {
        $input.data('xdsoft_datetimepicker')
          .find('td.xdsoft_disabled')
          .attr('title', $input.data('tooltip-disabled-dates'));
      },
    });

    $(input).closest('.input-group').find('.input-group-append').on('click', () => {
      $(input).datetimepicker('show');
    });

    $('#publish-asap').on('change', e => {
      input.disabled = e.currentTarget.checked;
      input.value = '';
    });

    /* $('.datetime-soonest')
      .text(minDateTime.format(DTFormat))
      .on('click', e => {
        e.preventDefault();
        $(input).val(minDateTime.format(DTFormat));
        $('#input').datetimepicker('setOptions', {value: minDateTime.toDate()});
        input.dispatchEvent(new Event('change'));
        updateDatePickerInfo(input);
      })
      .parent().removeClass('d-none'); */

    updateDatePickerInfo(input);
  }
};

const initStatusFormatting = () => {
  Array.from(document.querySelectorAll('[data-format-status][data-status]')).forEach(elem => {
    elem.innerHTML = statusFormatter(elem.dataset.status, { status_name: elem.dataset.statusName });
  })
};

const handleMultiModalChange = (targetContent) => {
  const targets = document.querySelectorAll(`[data-modal-content="${targetContent}"]`);
  if (!targets.length) return;

  Array.from(targets).forEach(target => {
    Array.from(target.parentElement.children).forEach(child => {
      if (typeof child.dataset.modalContent !== 'undefined') {
        child.classList.toggle('d-none', child !== target);
      }
    });
  });
};

const initMultiModal = () => {
  Array.from(document.querySelectorAll('[data-target-content]')).forEach(link => {

    link.addEventListener('click', event => {
      event.preventDefault();
      handleMultiModalChange(link.dataset.targetContent);
    });
  });
};

const handleSaveAndShowModal = e => {
  e.preventDefault();
  $(e.currentTarget.dataset.target).modal('show');
};

const initSaveAndShowModal = () => {
  Array.from(document.querySelectorAll('[data-action="save-and-show-modal"]')).forEach(link => {
    uniqueListener(link, 'click', handleSaveAndShowModal);
  });
};

const handleAuthForm = ({ form, options, data, callback }) => {
  form.addEventListener('submit', e => {
    e.preventDefault();

    Array.from(form.querySelectorAll('.error-msg')).forEach(errMsg => {
      errMsg.classList.add('d-none');
      errMsg.innerHTML = '';
    });

    API.call({ ...options, data: data() }).then(callback).catch(err => {
      // console.log('err.response:', err.response)
      if (err.response.data.error) {
        const errMsg = form.querySelector('.form-error-msg');
        errMsg.classList.remove('d-none');
        errMsg.innerHTML = err.response.data.error;
      }
      if (err.response.data.errors) {
        Object.entries(err.response.data.errors).forEach(([name, msgs]) => {
          const field = form.querySelector(`[name="${name}"]`);
          field.classList.add('error');
          let errMsg = field.nextElementSibling;
          while (errMsg && !errMsg.classList.contains('error-msg')) { errMsg = field.nextElementSibling }
          if (errMsg) {
            errMsg.innerHTML = Array.isArray(msgs) ? msgs.join(' ') : msgs;
            errMsg.classList.remove('d-none');
          }
        });
      }
    });
  });

  Array.from(form.querySelectorAll('[name]')).forEach(field => {
    field.addEventListener('change', e => {
      e.currentTarget.classList.remove('error');
      let errMsg = field.nextElementSibling;
      while (errMsg && !errMsg.classList.contains('error-msg')) { errMsg = errMsg.nextElementSibling }
      if (errMsg) {
        errMsg.classList.add('d-none');
        errMsg.innerHTML = '';
      }
    });
  });
};

const initAuthForms = () => {
  $('#authModal, #authModal-saved').on('hidden.bs.modal', e => {
    window.authCallback = {};
  });

  Array.from(document.querySelectorAll('.auth-form-login')).forEach(form => {
    handleAuthForm({
      form,
      options: {
        endpoint: API.endpoints.users.login,
      },
      data: () => ({
        user: {
          email: form.querySelector('[name="email"]').value,
          password: form.querySelector('[name="password"]').value,
          remember_me: form.querySelector('[name="remember_me"]').checked ? 1 : 0,
        },
      }),
      // callback: res => {
      // },
    });
  });

  Array.from(document.querySelectorAll('.auth-form-signup')).forEach(form => {
    handleAuthForm({
      form,
      options: {
        endpoint: API.endpoints.users.signup,
      },
      data: () => ({
        user: {
          email: form.querySelector('[name="email"]').value,
          ...(form.querySelector('[name="password"') && {
            password: form.querySelector('[name="password"]').value,
            password_confirmation: form.querySelector('[name="password_confirmation"]').value,
          }),
        },
      }),
      callback: res => {
        const tag = { category: 'user action', action: 'signup completed', label: 'popup in PR creation' };
        try {
          gtag('event', 'frontend', tag);
        } catch (e) {
          console.warn('Cannot set gtag event:', tag);
        }

        if (window.authCallback && typeof window.authCallback.signup === 'function') {
          $('.modal.show').modal('hide');
          window.authCallback.signup();
        } else {
          handleMultiModalChange('thank-you-signup');
        }
      },
    });
  });

  Array.from(document.querySelectorAll('.auth-form-resetpwd')).forEach(form => {
    handleAuthForm({
      form,
      options: {
        endpoint: API.endpoints.users.resetPassword,
      },
      data: () => ({
        user: {
          email: form.querySelector('[name="email"]').value,
        },
      }),
      callback: res => handleMultiModalChange('thank-you-resetpwd'),
    });
  });
};

const initActivityFeed = () => {
  const forms = document.querySelectorAll('.activity-form-comment:not(.started)');
  for (let form of forms) {
    form.addEventListener('submit', e => {
      e.preventDefault();
      try {
        const message = e.currentTarget.querySelector('[name="message"]').value;
        if (message) {
          API.call({
            endpoint: API.endpoints.release.notifications,
            params: { releaseUrl: window.location.href.replace(/(\/releases\/[^/?]+)([/?].*)*$/, '$1') },
            data: {
              release_notification: {
                message,
                notification_type: 'custom_message'
              }
            },
          }).then(res => {
            window.location.reload();
          });
        }
      } catch (err) {
        console.warn('error sending comment', err);
      }
    });
  }
};

const initVideoPauseOnModalClose = () => {
  $('.modal').on('hide.bs.modal', (e) => {
    $('iframe[src*="enablejsapi"]', e.currentTarget).each((i, iframe) => {
      iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    })
  });
};

const initInquiryModal = (prCreatorInstance) => {
  if (prCreatorInstance) {
    return;
  }

  const modals = {
    inquiry: document.querySelector('#modal-inquiry'),
    inquiryConfirmation: document.querySelector('#modal-inquiry-confirmation'),
  };

  if (!modals.inquiry || !modals.inquiryConfirmation) {
    return;
  }

  const inquiry = {
    triggers: document.querySelectorAll('.send-inquiry-btn'),
    msg: modals.inquiry.querySelector('[name="inquiry-msg"]'),
    submit: modals.inquiry.querySelector('#submit-inquiry'),
  };

  const orderId = window.location.href.replace(/^.*\/releases\/([^/?]+)([/?].*)*$/, '$1');

  const openInquiryModal = (e) => {
    e.preventDefault();
    $(modals.inquiry).modal('show');
  };

  const submitInquiry = (e) => {
    e.preventDefault();

    try {
      const message = inquiry.msg.value;
      if (message) {
        API.call({
          endpoint: API.endpoints.release.notifications,
          params: { releaseUrl: window.location.href.replace(/(\/releases\/[^/?]+)([/?].*)*$/, '$1') },
          data: {
            release_notification: {
              message,
              notification_type: 'custom_message'
            }
          },
        }).then(res => {
          $(modals.inquiry).modal('hide');

          Array.from(modals.inquiryConfirmation.querySelectorAll('[data-href-pattern]')).forEach(link => {
            link.href = link.dataset.hrefPattern.replace(/\{id\}/g, orderId);
          })
          $(modals.inquiryConfirmation).modal('show');
        });
      }
    } catch (err) {
      console.warn('error sending comment', err);
    }
  };

  Array.from(inquiry.triggers).forEach(trigger => {
    uniqueListener(trigger, 'click', openInquiryModal);
  })
  uniqueListener(inquiry.submit, 'click', submitInquiry);
};

const handleRegistrationButton = () => {
  const checkbox = document.getElementById('user_privacy_policy_consent');
  if (!checkbox) return;  // Exit if checkbox isn't on the current page

  const submitButton = document.querySelector('.btn-primary');
  checkbox.addEventListener('change', function () {
    submitButton.disabled = !this.checked;
  });
}

document.addEventListener("turbolinks:load", () => init());
