import Quill from 'quill';
import ImageToolkit from '@eloquentweb/quill-image-toolkit';

/* 
Works with inputs and textareas

USAGE: 
new RichEditor('textarea#some-id');
new RichEditor(textareaDomElement);
*/

const Delta = Quill.import("delta");

export class RichEditor {
  constructor(target, options = {}){
    this.options = options;

    this.initElements(target);
    this.initEditor();

    if(this.options.sideMenu){
      this.initSideMenu();
    }
  }

  initElements(target){
    this.dom = {
      target: typeof target === 'string' ? document.querySelector(target) : target,
      wrapper: document.createElement('div'),
      sideMenuFloating: document.querySelector('.richeditor-sideMenu-floating'),
      sideMenuButtons: document.querySelectorAll('.richeditor-sideMenu'),
    };

    this.dom.target.classList.add('d-none');
    this.dom.wrapper.classList.add('richeditor-wrapper');

    // this.dom.target.after(this.dom.sideMenu);
    this.dom.target.after(this.dom.wrapper);
  };

  initEditor(){
    // Quill.register('modules/imageToolkit', ImageToolkit);

    this.editor = new Quill(this.dom.wrapper, {
      placeholder: this.dom.target.placeholder,
      bounds: this.dom.wrapper,
      theme: 'bubble',
      formats: [
        'bold', 'italic', 'underline', 'link', 'size', 'strike', 'blockquote', 'header', 'list', 'align', 'direction', 'image', 'video', 
        'display', 'float', 'width', 'max-width', 'margin', 'alt', 'style'
      ],
      modules: {
        toolbar: [
          [{header: [2, 3, false]}, 'blockquote'], 
          [{align: []}], 
          ['bold', 'italic', 'underline'], 
          ['link'],
        ],
        keyboard: {
          bindings: this.options.bindings,
        },
        imageToolkit: {
          modules: ['Toolbar', 'Resize'],
        },
      },
    });

    this.dom.wrapper.addEventListener('click', (e) => {
      if(e.target.matches('.ql-toolbar button, .ql-toolbar button *')){        
        e.preventDefault();
      }
    });
    
    this.editor.pasteHTML(this.dom.target.value);
    // this.editor.clipboard.dangerouslyPasteHTML(this.dom.target.value);
    // console.log('this.dom.target.value:', this.dom.target.value)
    // console.log('this.editor.root.innerHTML:', this.editor.root.innerHTML)
    
    this.editor.on('text-change', () => {
      this.dom.target.value = this.editor.root.innerHTML;
      this.dom.target.dispatchEvent(new Event('change'));
    });
  };

  initSideMenu(){
    this.lastSelection = this.editor.getSelection();

    this.editor.on('editor-change', () => {
      const range = this.editor.getSelection();
      if(!range){
        this.dom.sideMenuFloating.classList.add('d-none');
      } else {
        this.lastSelection = range;
        const position = this.editor.getBounds(range.index);
        this.dom.sideMenuFloating.style.top = position.top + 'px';
        this.dom.sideMenuFloating.classList.remove('d-none');
      }
    });

    Array.from(this.dom.sideMenuButtons).forEach(button => {
      button.addEventListener('click', this.openSideMenu);
    });
  };

  openSideMenu = e => {
    if(typeof this.options.sideMenu.action !== 'function') return;

    this.options.sideMenu.action()
      .then(files => {
        files.forEach(file => {
          // console.log('file:', file.library, file.url, file.name, file)
          if(file.library === 'images'){
            this.editor.updateContents(
              new Delta()
                .retain(this.lastSelection ? this.lastSelection.index : this.editor.getLength())
                .insert({
                  image: {
                    src: file.url,
                    style: 'display: block; margin: 1em auto; max-width: 100%;',
                  }, 
                })
            );

            // this.editor.insertEmbed(
            //   this.lastSelection ? this.lastSelection.index : this.editor.getLength(), 
            //   'image', 
            //   {href: file.url, style: 'display: block'}, 
            //   'user'
            // );
          } else {
            this.editor.insertText(
              this.lastSelection ? this.lastSelection.index : this.editor.getLength(), 
              file.name,
              {
                link: file.url
              }
            );
          }
        })
      })
      .catch(err => null);
  };

  isFileUsed(url){
    const uses = this.editor.root.querySelector(`[src="${url}"], [href="${url}"]`);
    return !!uses;
  };

  removeFileUses(url){
    const uses = this.editor.root.querySelectorAll(`[src="${url}"], [href="${url}"]`);
    Array.from(uses).forEach(elem => elem.remove());
  };
};