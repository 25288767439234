export class MediaList {
    constructor(data) {
        this.data = data;
        this.init();
    }

    init() {
        this.renderRegionButtons();
        this.setupEventListeners();
    }

    renderRegionButtons() {
        const regions = this.groupByRegion();
        const regionButtonsContainer = document.querySelector('.region-buttons-container');
        regionButtonsContainer.innerHTML = '';

        Object.keys(regions).forEach((region, index) => {
            const button = document.createElement('button');
            button.textContent = region;
            button.className = 'btn btn-primary';
            button.dataset.target = `region-content-${index}`;
            regionButtonsContainer.appendChild(button);
            this.renderRegionContent(index, regions[region]);
        });
    }

    renderRegionContent(index, items) {
        const regionContentContainer = document.querySelector('.region-content-container');
        const regionDiv = document.createElement('div');
        regionDiv.className = 'card paper no-border region-group mt-5 mb-5';
        regionDiv.id = `region-content-${index}`;

        const cardHeader = document.createElement('div');
        cardHeader.className = 'card-header pt-3';
        const cardTitle = document.createElement('h3');
        cardTitle.className = 'h4 text-center card-title';
        cardTitle.textContent = items[0].region_name;
        cardHeader.appendChild(cardTitle);
        regionDiv.appendChild(cardHeader);

        const itemBody = document.createElement('div');
        itemBody.className = 'media-items-wrapper';
        const rowDiv = document.createElement('div');
        rowDiv.className = 'row';

        const template = document.getElementById('media-item-template').content;
        items.forEach(item => {
            const clone = document.importNode(template, true);
            clone.querySelector('.icon-placeholder').className += ` ${item.icon}`;
            clone.querySelector('.name-placeholder').textContent = item.name;
            clone.querySelector('.media-item-description').textContent = item.description;
            clone.querySelector('.download-link').href = item.attachment_url;
            rowDiv.appendChild(clone);
        });

        itemBody.appendChild(rowDiv);
        regionDiv.appendChild(itemBody);
        regionContentContainer.appendChild(regionDiv);
    }

    setupEventListeners() {
        document.querySelectorAll('.region-buttons-container button').forEach(button => {
            button.addEventListener('click', () => {
                const targetId = button.dataset.target;
                const targetElement = document.getElementById(targetId);
                targetElement.scrollIntoView({ behavior: 'smooth' });

                targetElement.style.border = "2px solid #7955C7";
                setTimeout(() => {
                    targetElement.style.border = "";
                }, 2000);
            });
        });
    }

    groupByRegion() {
        return this.data.reduce((acc, item) => {
            const regionName = item.region_name;
            if (!acc[regionName]) {
                acc[regionName] = [];
            }
            acc[regionName].push(item);
            return acc;
        }, {});
    }
}

document.addEventListener('turbolinks:load', () => {
    if (pageData?.mediaLists) {
        new MediaList(pageData.mediaLists);
    }
});