// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require('bootstrap')
require('js-cookie')
require('bootstrap/js/dist/tooltip')
window.moment = require('moment')
window.momentTimezone = require('moment-timezone')
require('jquery-datetimepicker')
require('bootstrap-table')
require('bootstrap-table/dist/locale/bootstrap-table-ja-JP.js')
require('malihu-custom-scrollbar-plugin')
require('frontend/app.js')
require('frontend/media-list.js')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
